define(['app', '$window', 'uaParser', 'throttle'], (app, $window , uaParser, Throttle) => {

  const DEVICE_STATE = '[data-device-state]';
  const PAYMENT_TYPE_LINK_CLASS = 'presentationalPaymentTypes_paymentTypeLink';
  const SHOW_CLASS_MODIFIER = '-show';

  const APPLE_PAY = 'applepay';
  const GOOGLE_PAY = 'googlepay';
  const WECHAT_PAY = 'wechatpay';

  const isReadyToPayRequest = {
    "apiVersion": 2,
    "apiVersionMinor": 0,
    "allowedPaymentMethods": [
      {
        "type": "CARD",
        "parameters": {
          "allowedAuthMethods": ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          "allowedCardNetworks": ["AMEX", "DISCOVER", "JCB", "MASTERCARD",
            "VISA"]
        }
      }
    ]
  };

  const checkIfGooglePayAvailable = async () => {
    const paymentsClient = new $window.google.payments.api.PaymentsClient(
      {environment: 'PRODUCTION'});
    try {
      const response = await paymentsClient.isReadyToPay(
        isReadyToPayRequest);
      return !!response;
    } catch (error) {
      return false;
    }
  };

  const presentationalPaymentTypes = () => {
    const component = {};

    component.init = async (element) => {
      component.element = element;

      component.deviceStateEl = component.element.querySelector(DEVICE_STATE);
      component.deviceState = window.getComputedStyle(component.deviceStateEl, ':before').getPropertyValue('content').replace(/\"/g, '');

      const getPaymentTypeLinks = async () => {
        const paymentTypeLinks = component.element.querySelectorAll(`.${PAYMENT_TYPE_LINK_CLASS}`);
        for (const link of paymentTypeLinks) {
          if (await component.shouldShowPaymentTypeLink(link)) {
            link.classList.add(`${PAYMENT_TYPE_LINK_CLASS + SHOW_CLASS_MODIFIER}`);
          }
          resizeEvent(link);
        }
      };

      const throttledPaymentTypeLinks = new Throttle(await getPaymentTypeLinks, 500);
      await getPaymentTypeLinks();
      window.addEventListener('resize', throttledPaymentTypeLinks.run);
    };

    const addScript = () => {
      return new Promise(resolve => {
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = false;
        script.src = 'https://pay.google.com/gp/p/js/pay.js';
        script.onload = resolve;
        head.appendChild(script);
      });
    };

    const resizeEvent = (el) => {
      const state = window.getComputedStyle(component.deviceStateEl, ':before').getPropertyValue('content').replace(/\"/g, '');
      browserCheck(el, state);
    };

    const browserCheck = (link, state) => {
      const parser = new uaParser();
      const userBrowser = parser.getBrowser();
      const linkAttr = link.getAttribute('data-payment-type');

      if ((linkAttr === WECHAT_PAY && state === 'desktop') || userBrowser.name === 'WeChat') {
        link.classList.add(`${PAYMENT_TYPE_LINK_CLASS + SHOW_CLASS_MODIFIER}`);
      } else if (linkAttr === WECHAT_PAY && state === 'mobile') {
        link.classList.remove(`${PAYMENT_TYPE_LINK_CLASS + SHOW_CLASS_MODIFIER}`);
      }
    };

    component.shouldShowPaymentTypeLink = async (link) => {
      if (link.getAttribute('data-payment-type') === APPLE_PAY) {
        return $window.ApplePaySession && $window.ApplePaySession.canMakePayments();
      } else if (link.getAttribute('data-payment-type') === GOOGLE_PAY) {
        await addScript();
        return await checkIfGooglePayAvailable();
      } else if (link.getAttribute('data-payment-type') === WECHAT_PAY) {
        return browserCheck(link, component.deviceState);
      } else return true;
    };

    return component;
  };


  return presentationalPaymentTypes;
});
